import axios from "axios";

const getCookie = (cookiename) => {
  let name = cookiename + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const BearerToken = getCookie("Bearer");
// console.log("BEARER TOKEN: ", BearerToken);

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${BearerToken}`;
  config.headers['x-upstream-env'] = process.env.REACT_APP_stargateENV;
  return config;
});

axiosInstance.interceptors.response.use((config) => {
  if (config.status == 401 || config.status == 403) {
    // This redirects to the home page if the user sees any 401 or 403 error.
    const homeUrl = process.env.REACT_APP_OHID_LogoutURL || "";
    // When the user receives a 401 or 403, we also remove the existing tokens.
    window.sessionStorage.removeItem("Bearer");
    window.sessionStorage.removeItem("SignedToken");
    window.location.replace(homeUrl);
  }
  return config;
});

export default axiosInstance;
