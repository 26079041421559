import { useEffect, useState } from "react";
import axios from "../../interceptors/interceptors";
import useWindowSize from "../../CustomHooks/useWindow";
import { SignedTokenGenerator } from "../../api/CreateSignedToken";
import DesktopView from "./DesktopView";
import PatientList from "./PatientList";
import PatientListMobileView from "./PatientListMobileView";
import { useSelector, useDispatch } from "react-redux";
import {
  saveCurrentScreen,
  saveProviderDetails,
  saveAuthcode,
} from "../../features/uiSlice";
import { appInsights } from "../Logger/appinsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import ErrorScreen from "./ErrorScreen";

const activeTracking = (oneHealthId, role, apcID) => {
  appInsights.trackEvent({
    name: "customLogs",
    properties: {
      userid: oneHealthId,
      type: "active",
      file: "AppLayout",
      role: role,
      apcId: apcID,
    },
  });
};

const AppLayout = (props) => {
  const dispatch = useDispatch();

  const providerDetails = useSelector(
    (state) => state.userDetails.providerDetails
  );
  const { isTablet, isMobile, onlySmallScreen } = useWindowSize();
  const [userID, setUserID] = useState(null);
  const [unknownUser, setUnknownUser] = useState(false);
  const [loadingUser, setLoadingUser] = useState(!["offline", "localhost"].includes(process.env.REACT_APP_Environment));
  const [jwtToken, setToken] = useState("");

  useEffect(() => {
    dispatch(saveCurrentScreen("AppLayout"));
    subscribe();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const userData = JSON.parse(
        window.sessionStorage.getItem("providerDetails")
      );
      if (userData !== null)
        activeTracking(userData.oneHealthId, userData.role, userData.apcId);
    }, 1000 * 60); // 60 seconds in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const loginTracking = (oneHealthId, role, apcID) => {
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: oneHealthId,
        type: "login",
        file: "AppLayout",
        role: role,
        apcId: apcID,
      },
    });
  };

  // const getSignedToken = async (atoken) => {
  //   const signedToken = await SignedTokenGenerator(atoken, (value) => {
  //     getProfileInfo(JSON.parse(window.sessionStorage.getItem("userID")), JSON.parse(window.sessionStorage.getItem("verifiedEmail")));


  //   }).catch((e) => {
  //     console.log(e);
  //   });
  //   return signedToken;
  // };

  const subscribe = () => {
    // Call the first function that gives you the response
    // let url = new URL(window.location.href);
    // let params = new URLSearchParams(url.search);
    // let code = params.get("code");
    getUserID()
      .then((response) => {
        // Execute the second function after getting the response
        // getSignedToken(response);
        getProfileInfo(JSON.parse(window.sessionStorage.getItem("userID")), JSON.parse(window.sessionStorage.getItem("verifiedEmail")));

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getUserID = async () => {
    if (
      Object.keys(providerDetails).length === 0 &&
      JSON.parse(window.sessionStorage.getItem("UserOnehealthID")) === null
    ) {
      try {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        //both code and accesstoken
        if (typeof props.match?.params.pcode !== "undefined") {
          let code = props.match.params.pcode;
          let atoken = props.match.params.acctoken;

          const response = await axios.get(
            process.env.REACT_APP_getUserInfo + code + "?accessToken=" + atoken
          );
          setUserID(response.data.preferred_username);
          window.sessionStorage.setItem(
            "userID",
            JSON.stringify(response.data.preferred_username)
          );
          window.sessionStorage.setItem(
            "verifiedEmail",
            JSON.stringify(response.data.email)
          );
          setToken(response.data.id_token);
          window.localStorage.setItem(
            "id_token",
            JSON.stringify(response.data.id_token)
          );
          window.sessionStorage.setItem(
            "atoken",
            JSON.stringify(response.data.OHIDAccessToken)
          );
          return response.data.OHIDAccessToken;
        } else if (params.has("code")) {
          let code = params.get("code");
          window.sessionStorage.setItem("authcode", JSON.stringify(code));
          dispatch(saveAuthcode(code));

          const response = await axios.get(
            process.env.REACT_APP_getUserInfo + code
          );

          setUserID(response.data.preferred_username);
          window.sessionStorage.setItem(
            "userID",
            JSON.stringify(response.data.preferred_username)
          );
          window.sessionStorage.setItem(
            "verifiedEmail",
            JSON.stringify(response.data.email)
          );
          setToken(response.data.id_token);
          window.localStorage.setItem("id_token", JSON.stringify(response.data.id_token));
          window.sessionStorage.setItem(
            "atoken",
            JSON.stringify(response.data.OHIDAccessToken)
          );
          return response.data.OHIDAccessToken;
        }
        else if (["offline", "localhost"].includes(process.env.REACT_APP_Environment)) {
          window.sessionStorage.setItem("providerDetails", JSON.stringify({
            "apcId": "999999",
            "firstName": "Robert",
            "lastName": "Walsh",
            "id": "RobertWalsh",
            "oneHealthId": "RobertWalsh",
            "useremail": "robert_walsh@optum.com",
            "role": "Devops Admin",
            // "role": "APC",
            "jwtToken": ""
          }));
          window.sessionStorage.setItem("UserOnehealthID", JSON.stringify("RobertWalsh"))
          window.sessionStorage.setItem("UserID", JSON.stringify("RobertWalsh"))
          window.sessionStorage.setItem("atoken", JSON.stringify("5DStox0i8f5XP4EBfhLrio89etJvKK1h"))
          window.localStorage.setItem("id_token", JSON.stringify("eyJraWQiOiJiMTRiOGRiNS05YjdmLTQ5MTctOGU5NS00NmVjZGNiYThmN2QiLCJhbGciOiJQUzUxMiJ9.eyJzdWIiOiI2NmY5Y2JmZi0wNWEzLTRlMTgtYTZkNi03YTQ5MDRiOGUxZjQiLCJiaXJ0aGRhdGUiOiIxOTcwLTAxLTAxIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImFtciI6WyJ0b3RwIiwic2lsZW50Il0sImlzcyI6Imh0dHBzOlwvXC9pZGVudGl0eS5ub25wcm9kLm9uZWhlYWx0aGNhcmVpZC5jb20iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJSb2JlcnRXYWxzaCIsImdpdmVuX25hbWUiOiJSb2JlcnQiLCJsb2NhbGUiOiJlbiIsImF1ZCI6ImdoYzAxODU2NU4iLCJhenAiOiJnaGMwMTg1NjVOIiwiYXV0aF90aW1lIjoxNzExNDg0ODA5LCJuYW1lIjoiUm9iZXJ0IFdhbHNoIiwibmlja25hbWUiOiJSb2JlcnQiLCJleHAiOjE3MTE0ODUzOTYsImZhbWlseV9uYW1lIjoiV2Fsc2giLCJpYXQiOjE3MTE0ODUwOTYsImVtYWlsIjoicm9iZXJ0X3dhbHNoQG9wdHVtLmNvbSIsImp0aSI6IjQ3YTg1NmRmLTRlMzQtNDdjMy1hYTI1LTYzMjZlZDAyOWE1NyJ9.Y6TzdKgprbZkxZq02uH6eFM0jywEMTytXyDWOIIzH-m6QJLoOJOR9IWcDRBCzPrseXUm-jbghwVEm6iMSaYiZCzOXykfB9HiwftAjF-00lQ6OACO594IkYlCDEBsVnDHcTnFvrgHFLcFQ5y0wLNBO-K1glVYDXYHk-l95Yt-RxxxQgUxqIe2ie4ErFB48lCOUxhqH295B8mJm9VlHJaeA6-BOMZQnB4VhpwtNEIEFbUTjhG4MpHatBrHGvzj_2WwiPpLHzrPlfvnBaSceAdCN-OoWi_pqBan00vy6OwHXvzH_PF6TvelO5fNGKwhBVGOJ3eF2JTUtoRrqcW_LYou-g"))
          window.sessionStorage.setItem("authcode", JSON.stringify("1hHxBkIAGVUnlDG8FDs4ZKTu7rdbqwDX"))
          return "RobertWalsh";
        } else {
          setUserID("");
          return "";
        }
      } catch (e) {
        console.log(e);
      }
    } else if (
      JSON.parse(window.sessionStorage.getItem("UserOnehealthID")) !== null
    ) {
      const userID = JSON.parse(
        window.sessionStorage.getItem("UserOnehealthID")
      );
      setUserID(userID);
      return JSON.parse(window.sessionStorage.getItem("atoken"));
    } else if (Object.keys(providerDetails).length === 0) {
      if (process.env.REACT_APP_Environment === "localhost"
        // || process.env.REACT_APP_Environment === "Dev"
      ) {
        setUserID("testuserhc1@getnada.com"); //externalAdmin,superAdmin,
        return "testuserhc1@getnada.com";
      }
    }
  };

  const getProfileInfo = async (userID, verifiedEmail) => {
    if (userID !== "" && typeof userID !== undefined && userID !== null) {
      try {
        setLoadingUser(true);

        const response = await axios.get(
          process.env.REACT_APP_getProfileInfo + "?oneHealthId=" + userID + "&verifiedEmail=" + verifiedEmail
          // ,{
          //   headers: {
          //     'Cookie': `SignedToken=${token}`,
          //   }
          // }
        );
        if (response.data !== null && response.data.oneHealthId !== undefined) {
          const obj = {
            apcId: response.data.apcId,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            id: response.data.id,
            oneHealthId: response.data.oneHealthId,
            useremail: response.data.useremail,
            role: response.data.role,
            jwtToken: jwtToken,
          };
          dispatch(saveProviderDetails(obj));
          window.sessionStorage.setItem("providerDetails", JSON.stringify(obj));
          window.sessionStorage.setItem(
            "UserOnehealthID",
            JSON.stringify(userID)
          );
          loginTracking(
            response.data.oneHealthId,
            response.data.role,
            response.data.apcId
          );
          activeTracking(response.data.oneHealthId,
            response.data.role,
            response.data.apcId
          );

          appInsights.setAuthenticatedUserContext(userID);
          appInsights.trackTrace({
            message: "Provider Information is successfully collected:" + userID,
            severityLevel: SeverityLevel.Information,
          });
          setLoadingUser(false);
          return response.data.oneHealthId;
        } else {
          setUnknownUser(true);
          setLoadingUser(false);
          return "";
        }
      } catch (e) {
        setUnknownUser(true);

        setLoadingUser(false);
        appInsights.trackException({
          error: e,
          severityLevel: SeverityLevel.Warning,
        });
        console.log(e);
        return "";
      }
    }

  };

  return (
    loadingUser === false && (
      <div id="appLayoutParentDiv">
        {
          unknownUser === true
            ?
            <ErrorScreen
              unknownUser={unknownUser}
              onlySmallScreen={onlySmallScreen}
            />
            :
            <div id="patientListParentDiv">
              {
                isMobile
                  ?
                  <div id="patientListMobileView">
                    {Object.keys(providerDetails).length !== 0 && (
                      <PatientListMobileView setAudioClient={props.setAudioClient} />
                    )}
                  </div>
                  :
                  <div id="patientListTabletAPCView">
                    {
                      Object.keys(providerDetails).length !== 0 &&
                        providerDetails.role === "APC" || providerDetails.role === "Demo User"
                        ?
                        <div id="patientListAdminView">
                          <PatientList
                            setAudioClient={props.setAudioClient}
                            setTranscript={props.setTranscript}
                            transcript={props.transcript}
                          />
                        </div>
                        :
                        <DesktopView
                          setAudioClient={props.setAudioClient}
                          setTranscript={props.setTranscript}
                          transcript={props.transcript}
                        />
                    }
                  </div>
              }
            </div>
        }
      </div>
    )
  );
};

export {AppLayout, activeTracking};